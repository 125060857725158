<template>
  <div class="profileDashboard" :class="'profileDashboard--' + dashboardType">
    <vue-scroll :ops="ops">
      <div v-if="dashboardType === 'flyer'" class="profileDashboard__wrapper" style="padding-right: 30px;">
        <div class="profileDashboard__left">
          <div class="profileDashboard__section">
            <div class="profileDashboard__title">Add members to share itineraries and encourage flight-sharing and cost-sharing activities</div>
            <div class="profileDashboard__content">
              <div class="profileDashboard__add-members">
                <div class="profileDashboard__add-members-tabs js-tabBox">
                  <div class="profileDashboard__add-members-tabs-nav">
                    <div class="profileDashboard__add-members-tabs-nav-item js-tabNavItem active">
                      FlyJets member
                    </div>
                  </div>
                  <div class="profileDashboard__add-members-tabs-content">
                    <div class="profileDashboard__add-members-tabs-content-item js-tabContentItem"
                         data-id="FlyJets-member">
                      <form
                        class="form form--addMembers"
                        id="addMemberForm"
                      >
                        <label class="form__label form__label--text">
                          <div class="form__field-wrapper">
                            <input
                              class="form__field"
                              type="email"
                              v-model="addEmail"
                              placeholder="Type here..."
                              autocomplete="off"
                            />
                            <span class="form__field-error">
                              <SvgIcon name="warning"/> not found!
                            </span>
                            <span class="form__field-success">
                              <SvgIcon name="checked-green"/> found!
                            </span>
                          </div>
                        </label>
                        <div class="form__label form__label--button">
                          <Spinner/>
                          <button class="form__button" type="button">
                            <span class="text">Add</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div ref="addTraveler" class="profileDashboard__add-members-input js-acc-item">
                  <div class="profileDashboard__add-members-input-header js-acc-title" @click="toggleAddContent">
                    <div class="profileDashboard__add-members-input-header-text">Or&nbsp;<b>input name and info
                      him/herself</b></div>
                    <div class="profileDashboard__add-members-input-header-icon">
                      <SvgIcon name="down-arrow"/>
                    </div>
                  </div>
                  <div class="profileDashboard__add-members-input-content js-add-content">
                    <div class="profileDashboard__add-members-input-form">

                      <form
                        class="form form--inputMembers js-ajaxForm whitePicker"
                        :class="[{'loading' : loadingFormMember}]"
                        @submit.prevent="submitAddMember"
                        id="inputMemberForm"
                      >
                        <Spinner/>
                        <label class="form__label form__label--text">
                          <div class="form__field-wrapper">
                            <div class="form__caption">First Name:</div>
                            <input
                              class="form__field"
                              type="text"
                              v-model="addedMember.firstName"
                              autocomplete="off"
                              :disabled="loadingFormMember"
                            />
                            <span
                              v-if="$v.addedMember.firstName.$dirty && !$v.addedMember.firstName.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                          </div>
                        </label>
                        <label class="form__label form__label--text">
                          <div class="form__field-wrapper">
                            <div class="form__caption">Last Name:</div>
                            <input
                              class="form__field"
                              type="text"
                              v-model="addedMember.lastName"
                              autocomplete="off"
                              :disabled="loadingFormMember"
                            />
                            <span
                              v-if="$v.addedMember.lastName.$dirty && !$v.addedMember.lastName.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                          </div>
                        </label>
                        <label class="form__label form__label--date">
                          <div class="form__field-wrapper">
                            <div class="form__caption">Date of birth:</div>
                            <input
                              class="profileDashboard__personal-info-field"
                              type="text"
                              v-mask="'##/##/####'"
                              v-model="addedMember.dateOfBirth"
                              :placeholder="dateFormat"
                              :disabled="loadingFormMember"
                              :getCalendarContainer="getPickerContainer"
                            />
                            <span
                              v-if="$v.addedMember.dateOfBirth.$dirty && !$v.addedMember.dateOfBirth.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                            <span
                              v-if="new Date(addedMember.dateOfBirth) == 'Invalid Date' && addedMember.dateOfBirth.length === 10"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              Invalid Date
                            </span>
                          </div>
                        </label>
                        <label class="form__label form__label--text">
                          <div class="form__field-wrapper">
                            <div class="form__caption">Mobile:</div>
                            <input
                              class="form__field"
                              type="text"
                              v-model="addedMember.mobile"
                              autocomplete="off"
                              :disabled="loadingFormMember"
                            />
                            <span
                              v-if="$v.addedMember.mobile.$dirty && !$v.addedMember.mobile.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                          </div>
                        </label>
                        <label class="form__label form__label--text">
                          <div class="form__field-wrapper">
                            <div class="form__caption">Email:</div>
                            <input
                              class="form__field"
                              type="text"
                              v-model="addedMember.email"
                              autocomplete="off"
                              :disabled="loadingFormMember"
                            />
                            <span
                              v-if="$v.addedMember.email.$dirty && !$v.addedMember.email.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                            <span
                              v-if="$v.addedMember.email.$dirty && !$v.addedMember.email.email"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              Enter a valid Email
                            </span>
                          </div>
                        </label>
                        <label class="form__label form__label--address">
                          <div class="form__field-wrapper">
                            <div class="form__caption">Address:</div>
                            <input
                              class="form__field"
                              type="text"
                              v-model="addedMember.address"
                              autocomplete="off"
                              :disabled="loadingFormMember"
                            />
                            <span
                              v-if="$v.addedMember.address.$dirty && !$v.addedMember.address.required"
                              class="form__field-invalid form__field-invalid--right-side"
                              style="color: #000"
                            >
                              This field is required
                            </span>
                          </div>
                        </label>
                        <div class="form__label form__label--button">
                          <button class="form__button" :disabled="loadingFormMember">
                            <span class="icon"><SvgIcon name="cloud"/></span>
                            <span class="text">Add</span>
                          </button>
                        </div>
                      </form>

                    </div>
                    <div class="profileDashboard__add-members-input-decor">
                      <SvgIcon name="plus"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profileDashboard__section">
            <div class="profileDashboard__title">Added members</div>
            <div class="profileDashboard__content">
              <div class="profileDashboard__members">
                <div v-if="!familyMembers.length" class="profileDashboard__members-empty js-members-empty">no members
                </div>
                <div
                  class="profileDashboard__members-item js-acc-item js-members-item"
                  v-for="(item, index) of familyMembers"
                  :key="index"
                >
                  <div class="profileDashboard__members-item-top">
                    <a class="profileDashboard__members-item-remove js-removeMemebers" @click="showModal(item.id)">
                      <SvgIcon name="close"/>
                    </a>
                    <div class="profileDashboard__members-item-top-inner js-acc-title" @click="toggleContent">
                      <div
                        v-if="item.photo"
                        class="profileDashboard__members-item-avatar"
                        :style="{backgroundImage: `url(${item.photo})`}"
                      >
                        <div class="profileDashboard__members-item-avatar-back">
                          <SvgIcon name="plus"/>
                        </div>
                      </div>
                      <div v-else class="profileDashboard__members-item-avatar">
                        <SvgIcon name="aeroplane2"/>
                        <div class="profileDashboard__members-item-avatar-back">
                          <SvgIcon name="plus"/>
                        </div>
                      </div>
                      <div class="profileDashboard__members-item-info">
                        <div class="profileDashboard__members-item-name">{{ item.firstName }} {{ item.lastName }}</div>
                        <div class="profileDashboard__members-item-descr">{{ item.descr }}</div>
                      </div>
                      <div class="profileDashboard__members-item-contacts">
                        <div class="profileDashboard__members-item-phone">{{ item.mobile }}</div>
                        <div class="profileDashboard__members-item-email">{{ item.email }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="profileDashboard__members-item-bottom js-acc-content">
                    <div class="profileDashboard__members-list">
                      <div class="profileDashboard__members-list-item">
                        <span class="profileDashboard__members-list-item-title">Birth:</span>
                        <span class="profileDashboard__members-list-item-value">{{ moment(item.dateOfBirth).format('MM/DD/YYYY') === 'Invalid date' ? '' : moment(item.dateOfBirth).format('MM/DD/YYYY') }}</span>
                      </div>
                      <div class="profileDashboard__members-list-item forMobile">
                        <span class="profileDashboard__members-list-item-title">Email:</span>
                        <a
                          class="profileDashboard__members-list-item-value"
                          href="mailto:annali@gmail.com">{{ item.email }}</a>
                      </div>
                      <div class="profileDashboard__members-list-item forMobile">
                        <span class="profileDashboard__members-list-item-title">Phone:</span>
                        <a
                          class="profileDashboard__members-list-item-value"
                          href="tel:+7888889977"
                        >{{ item.mobile }}</a>
                      </div>
                      <div class="profileDashboard__members-list-item">
                        <span class="profileDashboard__members-list-item-title">Address:</span>
                        <span class="profileDashboard__members-list-item-value">{{ item.address }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <QuestionPopup
          :name="'questionPopupAddMember'"
          :title="'The member will be removed'"
          :text="'Are you sure?'"
          @remove="remove"
        />
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Spinner from '@/components/common/Spinner/Spinner'
import QuestionPopup from '@/components/common/modals/QuestionPopup'
import {email, required} from 'vuelidate/lib/validators'
import moment from 'moment'
import 'vue-advanced-cropper/dist/style.css'
import '@/views/profile/Profile.styl'

export default {
  props: ['dashboardType'],
  name: 'Members',
  data: () => ({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobile: '',
    invalidDate: false,
    address: '',
    companyName: '',
    companyAddress: '',
    companyEmail: '',
    companyPhone: '',
    workEmail: '',
    workPhone: '',
    currentMember: null,
    addedMember: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      mobile: '',
      email: '',
      address: '',
    },
    // tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    editing: false,
    loadingPhoto: false,
    imageUrl: '',
    avatar: '',
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    dateFormat: 'MM/DD/YYYY',
    loadingForm: false,
    loadingFormMember: false,
    addEmail: '',
    inviteEmail: '',
    editImg: {
      coordinates: {},
      url: '',
    },
    uploader: {
      files: [],
      edit: false,
    },
    uploaderDocument: {
      files: [],
      edit: false,
    },
  }),
  validations: {
    firstName: {required},
    lastName: {required},
    email: {
      email,
      required,
    },
    mobile: {required},
    workEmail: {email},
    addedMember: {
      firstName: {required},
      lastName: {required},
      dateOfBirth: {required},
      mobile: {required},
      email: {
        email,
        required,
      },
      address: {required},
    },
    address: {required},
    companyAddress: {required},
    companyEmail: {email},
    addEmail: {
      email,
      required,
    },
    inviteEmail: {
      email,
      required,
    },
  },
  mounted () {
    const insertContainer = document
      .querySelector('.profileDashboard__left')
      .querySelector('.profileDashboard__section')
    const docs = document.querySelector('.js-doc')

    if (docs && document.querySelector('html').classList.contains('d-mobile')) {
      insertContainer.appendChild(docs)
    }
  },
  computed: {
    familyMembers () {
      return this.$store.state.profile.familyMembers
    },
  },
  methods: {
    moment,
    toggleAddContent (event) {
      const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-add-content')
      const status = this.$root.animations.slideToggle(item)
      status ? this.$refs.addTraveler.classList.add('open') : this.$refs.addTraveler.classList.remove('open')
    },
    getPickerContainer (trigger) {
      return document.querySelector('.whitePicker')
    },
    toggleContent (event) {
      const item = event.currentTarget.closest('.js-members-item').querySelector('.js-acc-content')
      this.$root.animations.slideToggle(item)
    },
    showModal (item) {
      this.$modal.show('questionPopupAddMember')
      this.currentMember = item
    },
    async remove () {
      await this.$store.dispatch('deleteFamilyMember', this.currentMember)
    },
    async submitAddMember () {
      if (
        this.$v.addedMember.firstName.$invalid ||
        this.$v.addedMember.lastName.$invalid ||
        this.$v.addedMember.dateOfBirth.$invalid ||
        this.$v.addedMember.mobile.$invalid ||
        this.$v.addedMember.email.$invalid ||
        this.$v.addedMember.address.$invalid ||
        (new Date(this.addedMember.dateOfBirth) === 'Invalid Date' && this.addedMember.dateOfBirth.length === 10)
      ) {
        this.$v.$touch()
        return false
      }

      this.loadingFormMember = true

      const data = {
        firstName: this.addedMember.firstName,
        lastName: this.addedMember.lastName,
        dateOfBirth: this.addedMember.dateOfBirth,
        mobile: this.addedMember.mobile,
        email: this.addedMember.email,
        address: this.addedMember.address,
      }

      await this.$store.dispatch('addFamilyMember', data)

      this.loadingFormMember = false

      const message = {
        title: 'Member Added',
        text: 'Member has been added successfully',
      }

      this.$modal.show('ThanksPopup', {message})

      this.addedMember.firstName = '';
      this.addedMember.lastName = '';
      this.addedMember.dateOfBirth = '';
      this.addedMember.mobile = '';
      this.addedMember.email = '';
      this.addedMember.address = '';
    },
  },
  watch: {
    dateOfBirth () {
      if (new Date(this.dateOfBirth) === 'Invalid Date' && this.dateOfBirth.length === 10) {
        this.invalidDate = true;
      } else {
        this.invalidDate = false;
      }
    },
  },
  components: {
    SvgIcon,
    Spinner,
    QuestionPopup,
  },
}
</script>
